import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import ReactMarkdown from "react-markdown";

const ImpressumPage = ({ data }) => {
  return (
    <div>
      <Layout>
        <div className="container mx-auto prose mt-12">
          <h1>Impressum</h1>
          <ReactMarkdown>{data.strapiImpressum.content}</ReactMarkdown>
        </div>
      </Layout>
    </div>
  );
};

export const queryImpressum = graphql`
  query {
    strapiImpressum {
      content
    }
  }
`;

export default ImpressumPage;
